import Swiper, {
  Pagination, Navigation, Autoplay, EffectFade,
} from 'swiper';
import Choices from 'choices.js';
import MicroModal from 'micromodal';
import IMask from 'imask';
import '@justinribeiro/lite-youtube';
import GLightbox from 'glightbox';
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';
import tippy from 'tippy.js/headless';
import {
  setDefaultOptions, fromUnixTime, format, parse,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import LocomotiveScroll from 'locomotive-scroll';
import * as yup from 'yup';
import { Dropzone } from 'dropzone';
import Cookies from 'js-cookie';
// import throttle from 'underscore/modules/throttle.js';
//import { document } from 'postcss';
import Burger from './components/burger';

Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);

window.choicesInstances = [];

window.yup = yup;

window.Dropzone = Dropzone;

window.Cookies = Cookies;

setDefaultOptions({
  locale: ru,
  weekStartsOn: 1,
});

window.dateFns = {
  fromUnixTime,
  format,
  parse,
};

const media = window.matchMedia('(max-width: calc(1200px - 1px))');
if (media.matches) {
  const burger = new Burger({
    node: document.querySelector('.js_menu-wrap'),
    anchor: document.querySelector('.header__main-wrap'),
  });
}

// submenu
document.addEventListener('DOMContentLoaded', () => {
  const submenuBtns = document.querySelectorAll('.js_btn-submenu');
  const headerLogo = document.querySelector('.js_header-logo');
  submenuBtns.forEach((submenuBtn) => {
    const submenuParent = submenuBtn.closest('.js_submenu-parent');
    submenuBtn.addEventListener('click', (event) => {
      event.preventDefault();
      if (media.matches) {
        submenuParent.classList.toggle('open');
      }
    });

    submenuParent.addEventListener('mouseover', () => {
      if (!media.matches) {
        submenuParent.classList.add('open');
        if (!submenuParent.classList.contains('header-schedule')) {
          headerLogo.classList.add('open');
        }
      }
    });
    submenuParent.addEventListener('mouseout', () => {
      if (!media.matches) {
        submenuParent.classList.remove('open');
        headerLogo.classList.remove('open');
      }
    });
  });
});

// slider home hero
try {
  const homeHeroSwiperSlides = document.querySelectorAll('.js_home-hero-slider .swiper-slide');
  let homeHeroSwiperHeight;

  if (window.matchMedia('(max-width: 768px)').matches) {
    homeHeroSwiperHeight = true;
  } else {
    homeHeroSwiperHeight = false;
  }

  const homeHeroSlider = new Swiper('.js_home-hero-slider.swiper', {
    slidesPerView: 1,
    speed: 1500,
    loop: homeHeroSwiperSlides.length > 1,
    pagination: {
      el: '.js_home-hero-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.js_home-hero-slider .js_btn-slide-next',
      prevEl: '.js_home-hero-slider .js_btn-slide-prev',
    },
    autoHeight: homeHeroSwiperHeight,
  });
} catch (e) { }

// slider home hero
try {
  const homeHeroSlider = new Swiper('.js_home-hero-doctors.swiper', {
    slidesPerView: 1,
    speed: 1500,
    loop: true,
    noSwiping: true,
    noSwipingClass: 'swiper-slide',
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
    },
  });
} catch (e) { }

// slider home directions
try {
  const homeDirectionsSlider = new Swiper('.js_home-directions-slider.swiper', {
    slidesPerView: 'auto',
    loop: true,
    speed: 1500,
    spaceBetween: 32,
    autoHeight: true,
    centeredSlides: true,
    pagination: {
      el: '.js_home-directions-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      click() {
        homeDirectionsSlider.slideTo(this.clickedIndex);
      },
    },
  });
} catch (e) { }

// slider reviews doctor page

try {
  const revNew = document.querySelector('.js_reviews').dataset.new;
  if (revNew) {
    const doctorReviewsSlider = new Swiper('.js_reviews .swiper', {
      slidesPerView: 'auto',
      spaceBetween: 32,
      pagination: {
        el: '.js_reviews .js_slider-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.js_reviews .js_btn-slide-next',
        prevEl: '.js_reviews .js_btn-slide-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 1.03,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  } else {
    const doctorReviewsSlider = new Swiper('.js_reviews .swiper', {
      slidesPerView: 'auto',
      spaceBetween: 32,
      pagination: {
        el: '.js_reviews .js_slider-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.js_reviews .js_btn-slide-next',
        prevEl: '.js_reviews .js_btn-slide-prev',
      },
      breakpoints: {
        320: {
          centeredSlides: true,
        },
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  }
} catch (e) { }

// text more reviews doctor page
try {
  const reviewMoreBtns = document.querySelectorAll('.js_text-more-btn');
  reviewMoreBtns.forEach((reviewMoreBtn) => {
    reviewMoreBtn.addEventListener('click', () => {
      const reviewTextItem = reviewMoreBtn.closest('.js_text-item');
      const reviewMoreBtnText = reviewMoreBtn.querySelector('.js_text-more-btn-val');
      if (!reviewTextItem.classList.contains('open')) {
        reviewTextItem.classList.add('open');
        reviewMoreBtnText.textContent = 'Скрыть';
      } else {
        reviewTextItem.classList.remove('open');
        reviewMoreBtnText.textContent = 'Развернуть';
      }
    });
  });
} catch (e) { }

// slider news doctor page
try {
  const doctorNewsSlider = new Swiper('.js_doctor-news .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js_doctor-news .js_btn-slide-next',
      prevEl: '.js_doctor-news .js_btn-slide-prev',
    },
    pagination: {
      el: '.js_doctor-news .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  });
} catch (e) { }

// home team slider
try {
  document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js_home-team-slider')) {
      let swiperInit = false;
      let homeTeamSlider;
      function enableSwiper() {
        if (window.innerWidth <= 991) {
          if (!swiperInit) {
            swiperInit = true;
            homeTeamSlider = new Swiper('.js_home-team-slider .swiper', {
              slidesPerView: 1,
              pagination: {
                el: '.js_home-team-slider .js_slider-pagination',
                type: 'bullets',
                clickable: true,
              },
            });
          }
        } else if (swiperInit) {
          homeTeamSlider.destroy();
          swiperInit = false;
        }
      }
      enableSwiper();
      window.addEventListener('resize', enableSwiper);
    }
  });
} catch (e) { }

// custom select
try {
  document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.js-choice');
    selects.forEach((select) => {
      const choices = new Choices(select, {
        searchEnabled: false,
        searchChoices: false,
        itemSelectText: '',
        shouldSort: false,
        // removeItemButton: true,
        // renderSelectedChoices: 'always',
      });
      window.choicesInstances.push(choices);
      const selectMultiple = document.querySelector('.js_select-multiple .choices__inner');
      const choicesBlock = document.querySelector('.js_select-multiple .choices');
      if (selectMultiple) {
        selectMultiple.addEventListener('click', () => {
          if (choicesBlock.classList.contains('is-open')) {
            choices.hideDropdown();
          }
        });
      }
    });
  });
} catch (e) { }

// custom select with search
try {
  document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.js-choice-search');
    selects.forEach((select) => {
      const choicesSettings = {
        searchEnabled: true,
        searchChoices: true,
        itemSelectText: '',
        shouldSort: false,
        noResultsText: 'Совпадений не найдено',
        // removeItemButton: true,
        // renderSelectedChoices: 'always',
      };

      // if (select.name === 'doctorcategory') {
      //   choicesSettings.placeholder = true;
      //   choicesSettings.placeholderValue = 'Выберите специальность';
      // }

      const choices = new Choices(select, choicesSettings);
      window.choicesInstances.push(choices);

      const selectMultiple = document.querySelector('.js_select-multiple .choices__inner');
      const choicesBlock = document.querySelector('.js_select-multiple .choices');
      if (selectMultiple) {
        selectMultiple.addEventListener('click', () => {
          if (choicesBlock.classList.contains('is-open')) {
            choices.hideDropdown();
          }
        });
      }
    });
  });
} catch (e) { }

// map
// try {
//   ymaps.ready(mapInit);
//   function mapInit() {
//     const centerPosMap = [44.880532, 37.330076];

//     const geoMap = new ymaps.Map('geo-map', {
//       center: centerPosMap,
//       zoom: 13,
//       controls: ['zoomControl'],
//       behaviors: ['drag'],
//     });

//     const mapPlacemark = new ymaps.Placemark(
//       geoMap.getCenter(),
//       {},
//       {
//         iconLayout: 'default#image',
//         iconImageHref: '/theme/assets/img/marker.png',
//         iconImageSize: [47, 64],
//         iconImageOffset: [-30, -40],
//       },
//     );
//     // geoMap.controls.remove('zoomControl');
//     // geoMap.behaviors.disable(['scrollZoom']);
//     // geoMap.behaviors.disable('drag');
//     geoMap.geoObjects.add(mapPlacemark);
//   }
// } catch (e) {}

// modal example
try {
  const modalOverlay = document.querySelector('.js-modal-overlay');
  const modalConfig = {
    openClass: 'modal--show',
    openTrigger: 'data-modal-open',
    closeTrigger: 'data-modal-close',
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    onShow: () => {
      document.querySelector('body').classList.add('scroll-lock');
      modalOverlay.classList.add('modal-overlay--show');
      modalOverlay.classList.remove('modal-overlay--hidden');
    },
    onClose: () => {
      document.querySelector('body').classList.remove('scroll-lock');
      modalOverlay.classList.add('modal-overlay--hidden');
      modalOverlay.classList.remove('modal-overlay--show');
    },
  };

  MicroModal.init(modalConfig);
} catch (e) { }

// input tel
try {
  document.addEventListener('DOMContentLoaded', () => {
    const telInputs = document.querySelectorAll('.js-form-input-phone');
    telInputs.forEach((telInput) => {
      IMask(telInput, {
        mask: '+{7} (000) 000-00-00',
      });
    });

    const innInputs = document.querySelectorAll('.js-form-input-inn');
    innInputs.forEach((input) => {
      IMask(input, {
        mask: '0000-000000-00',
      });
    });
  });
} catch (e) { }

// scroll
try {
  const scrollLinks = document.querySelectorAll('.js_scroll-link');
  scrollLinks.forEach((el) => {
    el.addEventListener('click', function (e) {
      e.preventDefault();

      const href = this.getAttribute('href').substring(1);
      const scrollTarget = document.getElementById(href);

      const elementPosition = scrollTarget.getBoundingClientRect().top;

      window.scrollBy({
        top: elementPosition,
        behavior: 'smooth',
      });
    });
  });
} catch (e) { }

// slider news-inner page
try {
  const newsInnerSlider = new Swiper('.js_news-inner-slider .swiper', {
    slidesPerView: 1,
    navigation: {
      nextEl: '.js_news-inner-slider .js_btn-slide-next',
      prevEl: '.js_news-inner-slider .js_btn-slide-prev',
    },
    pagination: {
      el: '.js_news-inner-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
} catch (e) { }

// hidden video btn
try {
  const mediaBlocks = document.querySelectorAll('.js_media');
  mediaBlocks.forEach((mediaItem) => {
    mediaItem.addEventListener('click', () => {
      if (!mediaItem.classList.contains('open')) {
        mediaItem.classList.add('open');
      }
    });
  });
} catch (e) { }

// doctors slider
try {
  const doctorsSlider = new Swiper('.js_doctors-slider .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js_doctors-slider .js_btn-slide-next',
      prevEl: '.js_doctors-slider .js_btn-slide-prev',
    },
    pagination: {
      el: '.js_doctors-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      990: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
    },
  });
} catch (e) { }

// try {
//   window.asscroll = new ASScroll({ disableRaf: true, limitLerpRate: true });

//   window.addEventListener('load', () => {
//     window.asscroll.enable();
//   });
// } catch (e) {}

// header popup show/close
try {
  const headerPopupBtns = document.querySelectorAll('.js_popup-btn');
  const headerPopupBtnClose = document.querySelector('.js_popup-btn-close');

  headerPopupBtns.forEach((headerPopupBtn) => {
    headerPopupBtn.addEventListener('mouseover', () => {
      headerPopupBtn.classList.add('open');
    });

    headerPopupBtn.addEventListener('mouseout', () => {
      headerPopupBtn.classList.remove('open');
    });

    if (headerPopupBtnClose) {
      headerPopupBtnClose.addEventListener('click', () => {
        headerPopupBtn.classList.remove('open');
      });
    }
  });
} catch (e) { }

// slider hero landing page
try {
  const heroLandingSwiperSlides = document.querySelectorAll(
    '.js_landing-hero-slider .swiper-slide',
  );
  const landingHeroSlider = new Swiper('.js_landing-hero-slider.swiper', {
    slidesPerView: 1,
    speed: 1500,
    loop: heroLandingSwiperSlides.length > 1,
    pagination: {
      el: '.js_landing-hero-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.js_landing-hero-slider .js_btn-slide-next',
      prevEl: '.js_landing-hero-slider .js_btn-slide-prev',
    },
  });
} catch (e) { }

// slider landing paid landing hospital page
try {
  const landingPaidSlider = new Swiper('.js_landing-paid-slider.swiper', {
    slidesPerView: 1,
    speed: 1500,
    loop: true,
    spaceBetween: 32,
    pagination: {
      el: '.js_landing-paid-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
} catch (e) { }

// slider surgery slider landing surgery page
try {
  const landingSurgerySlider = new Swiper('.js_surgery-slider .swiper', {
    slidesPerView: 1,
    speed: 1500,
    pagination: {
      el: '.js_surgery-slider .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.js_surgery-slider .js_btn-slide-next',
      prevEl: '.js_surgery-slider .js_btn-slide-prev',
    },
  });
} catch (e) { }

// slider landing page
try {
  const landingSliders = document.querySelectorAll('.js_landing-slider');
  landingSliders.forEach((landingSlider) => {
    const sliderContent = landingSlider.querySelector('.swiper');
    const btnNext = landingSlider.querySelector('.js_btn-slide-next');
    const btnPrev = landingSlider.querySelector('.js_btn-slide-prev');
    const sliderPagination = landingSlider.querySelector('.js_slider-pagination');

    let centeredSlidesOnMobile;

    if (landingSlider.classList.contains('js_landing-slider-center')) {
      centeredSlidesOnMobile = true;
    } else {
      centeredSlidesOnMobile = false;
    }

    const sliderLanding = new Swiper(sliderContent, {
      slidesPerView: 'auto',
      spaceBetween: 32,
      pagination: {
        el: sliderPagination,
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: btnNext,
        prevEl: btnPrev,
      },
      breakpoints: {
        320: {
          centeredSlides: centeredSlidesOnMobile,
        },
        575: {
          centeredSlides: false,
        },
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  });
} catch (e) { }

// slider mob
try {
  let initSliderMob;
  let swiperSliderMob = false;

  const mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
  const tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
  const desktop = window.matchMedia('(min-width: 1025px)');

  if (mobile.matches) {
    if (!initSliderMob) {
      initSliderMob = true;
      swiperSliderMob = new Swiper('.js_landing-slider-mob .swiper', {
        slidesPerView: 'auto',
        spaceBetween: 32,
        pagination: {
          el: '.js_landing-slider-mob .js_slider-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {

        },
        breakpoints: {
          320: {
            centeredSlides: true,
          },
          575: {
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
    }
  } else if (tablet.matches) {
    swiperSliderMob.destroy();
    initSliderMob = false;
  } else if (desktop.matches) {
    swiperSliderMob.destroy();
    initSliderMob = false;
  }
} catch (e) { }

// video index page
try {
  const homeVideoSection = document.querySelector('.js_home-video-section');
  const player = document.querySelector('.js_home-video');
  const isPlaying = player.currentTime > 0
    && !player.paused
    && !player.ended
    && player.readyState > player.HAVE_CURRENT_DATA;

  function playBgVideo() {
    homeVideoSection.classList.add('play');
    if (!isPlaying) {
      player.play();
    }
  }
  function stopBgVideo() {
    homeVideoSection.classList.remove('play');
    player.pause();
  }

  if (!media.matches) {
    homeVideoSection.addEventListener('mouseover', () => {
      playBgVideo();
    });

    homeVideoSection.addEventListener('mouseout', () => {
      stopBgVideo();
    });
  } else {
    homeVideoSection.addEventListener('click', () => {
      playBgVideo();
    });
    window.addEventListener('click', (event) => {
      const target = event.target;
      if (!target.closest('.js_home-video-section')) {
        stopBgVideo();
      }
    });
  }
} catch (e) { }

// try {
const imageLightbox = new GLightbox({
  selector: '.js-glightbox-image',
  type: 'image',
});

function lightboxBlur() {
  const galleryItems = document.querySelectorAll('.js-glightbox-image');

  galleryItems.forEach((item, i) => {
    if (item.classList.contains('js-gallery-item-blur')) {
      const gslideItem = document.querySelectorAll('.gslide')[i];
      const gslideItemMedia = gslideItem.querySelector('.gslide-media');

      const blurContent = document.createElement('div');
      blurContent.classList.add('blur-layer');
      blurContent.innerHTML = `
              <p class="title title--middle">Изображение деликатного характера</p>
              <p class="blur-layer__text">
                  <button class="blur-layer__btn js_blur-layer-close" type="button">Нажмите</button>,
                  чтобы убрать размытие
              </p>
          `;

      if (gslideItemMedia) {
        gslideItemMedia.appendChild(blurContent);

        const blurLayerCloseBtn = blurContent.querySelector('.js_blur-layer-close');
        blurLayerCloseBtn.addEventListener('click', () => {
          blurContent.classList.add('hidden');
        });
      }
    }
  });
}
function lightboxVideoBlur() {
  const galleryItems = document.querySelectorAll('.js-glightbox-video-stop');

  galleryItems.forEach((item, i) => {
    if (item) {
      const gslideItem = document.querySelectorAll('.gslide')[i];
      const gslideItemMedia = gslideItem.querySelector('.gslide-media');

      const blurContent = document.createElement('div');
      blurContent.classList.add('blur-layer');
      blurContent.innerHTML = `
              <p class="title title--middle">Видео деликатного характера</p>
              <p class="blur-layer__text">
                  <button class="blur-layer__btn js_blur-layer-close" type="button">Нажмите</button>,
                  чтобы убрать размытие
              </p>
          `;

      if (gslideItemMedia) {
        gslideItemMedia.appendChild(blurContent);

        const blurLayerCloseBtn = blurContent.querySelector('.js_blur-layer-close');
        blurLayerCloseBtn.addEventListener('click', () => {
          blurContent.classList.add('hidden');
        });
      }
    }
  });
}
imageLightbox.on('open', () => lightboxBlur());
// } catch (e) { }

try {
  const videoLightbox = new GLightbox({
    selector: '.js-glightbox-video',
    type: 'video',
    source: 'youtube',
  });

  const videoLightboxStop = new GLightbox({
    selector: '.js-glightbox-video-stop',
    type: 'video',
    source: 'youtube',
    autoplayVideos: false,
  });

  videoLightboxStop.on('open', () => lightboxVideoBlur());
} catch (e) { }

// Calendar
try {
  const formCalendars = document.querySelectorAll('.js-calendar');
  formCalendars.forEach((formCalendar) => {
    const calendarPicker = new Litepicker({
      element: formCalendar,
      format: 'DD.MM.YYYY',
      lang: 'ru-RU',
      plugins: ['mobilefriendly'],
      showTooltip: false,
      buttonText: 'ДД/ММ/ГГГГ',
    });
    calendarPicker.on('selected', (val) => {
      formCalendar._x_model.set(dateFns.format(val.dateInstance, 'dd.MM.yyyy'));
      const evt = new CustomEvent('datechange');
      formCalendar.dispatchEvent(evt);
    });
  });

  // eslint-disable-next-line no-empty
} catch (e) { }

// tippy.js surgery popups
// try {
const typ = tippy('.js_surgery-part', {
  content(ref) {
    const tooltipTplId = ref.dataset.id;
    const tpl = document.querySelector(`template[data-tooltip="${tooltipTplId}"]`);
    return tpl.content.cloneNode(true);
  },
  allowHTML: true,
  placement: 'top',
  appendTo: document.body,
  interactive: true,
  onTrigger(instance) {
    if (instance.reference.dataset.pos) {
      instance.setProps({ placement: instance.reference.dataset.pos });
    }
    const tooltipOpenId = parseInt(instance.reference.dataset.id);
    const surgeryMask = document.querySelector(`[data-mask="${tooltipOpenId}"]`);
    surgeryMask.style.opacity = 1;
  },
  onUntrigger(instance) {
    const tooltipOpenId = parseInt(instance.reference.dataset.id);
    const surgeryMask = document.querySelector(`[data-mask="${tooltipOpenId}"]`);
    surgeryMask.style.opacity = 0;
  },
  render(instance) {
    // The recommended structure is to use the popper as an outer wrapper
    // element, with an inner `box` element
    const popper = document.createElement('div');
    popper.appendChild(instance.props.content);

    function onUpdate(prevProps, nextProps) {
      // DOM diffing
      if (prevProps.content !== nextProps.content) {
        popper.textContent = '';
        popper.appendChild(nextProps.content);
      }
    }

    // Return an object with two properties:
    // - `popper` (the root popper element)
    // - `onUpdate` callback whenever .setProps() or .setContent() is called
    return {
      popper,
      onUpdate, // optional
    };
  },
});
// typ[3].show();
// } catch (e) {}

// reviews page offset when switching to the anchor link
try {
  document.addEventListener('DOMContentLoaded', () => {
    if (window.location.hash !== '') {
      setTimeout(() => {
        const scrollTarget = document.getElementById(window.location.hash.substring(1));
        const headerFixed = document.querySelector('.header__top-wrap');
        const elementPosition = scrollTarget.getBoundingClientRect().top - headerFixed.offsetHeight;

        window.scrollBy({
          top: elementPosition,
          behavior: 'smooth',
        });
      }, 200);
    }
  });
} catch (e) { }

try {
  const directionAccordion = document.querySelector('.direction-accordion__top');
  directionAccordion.addEventListener('click', (e) => {
    e.preventDefault();
    directionAccordion.parentNode.classList.toggle('active');
  });
} catch (e) { }
// Scroll animation home page
try {
  document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('[data-scroll-container]')) {
      const mediaDesktopHomeAnimation = window.matchMedia('(max-width: calc(1200px - 1px))');
      const mediaMobileHomeAnimation = window.matchMedia('(max-width: calc(575px - 1px))');
      const headerMain = document.querySelector('.header__main');
      if (!mediaDesktopHomeAnimation.matches) {
        const onScroll = (args) => {
          for (const i in args.currentElements) {
            if (typeof args.currentElements[i] === 'object' && animations[i]) {
              animations[i](args.currentElements[i].el, args.currentElements[i].progress);
            }
          }
          // add/remove class header-main block
          if (args.scroll.y > 50) {
            headerMain.classList.add('is-scroll');
          } else {
            headerMain.classList.remove('is-scroll');
          }
        };

        // const onScrollThrottled = throttle(onScroll, 50);

        window.locoScroll = new LocomotiveScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          mobile: {
            smooth: true,
          },
        });
        document.querySelector('body').style.opacity = 1;
        window.locoScroll.on('scroll', onScroll);

        // Add attr scroll direction active slide
        const diractionsSlider = document.querySelector('.js_home-directions-slider');
        const diractionsItem = diractionsSlider.querySelector(
          '.swiper-slide-active .home-directions__item',
        );
        diractionsItem.setAttribute('data-scroll', '');
        diractionsItem.setAttribute('data-scroll-id', 'home-directions-item');

        function scaleTitle(element, progress) {
          element.style.opacity = progress * 2;
          const scale = (1 - progress) * 2;
          if (scale > 1) {
            element.style.transform = `scale(${scale})`;
          }
        }

        const animations = {
          'home-directions-title': scaleTitle,
          'home-news-title': scaleTitle,
          'home-reviews-title': scaleTitle,
          'home-centers-title': scaleTitle,
          'home-promo-title': scaleTitle,
          'home-video-title': scaleTitle,
          'scroll-sections': function (element, progress) {
            const logoSticky = element.querySelector('.home-sections__logo img');
            if (logoSticky) {
              logoSticky.style.transform = `translateY(${1.5 * progress * 80 - 20}vh)`;
            }
          },
          'home-directions-item': function (element, progress) {
            const scale = progress + 0.6;
            if (scale < 1 && !element.classList.contains('scale-stop')) {
              element.style.transform = `scale(${scale})`;
            } else {
              element.style.transform = `scale(${1})`;
              element.classList.add('scale-stop');
            }
          },
        };
      }

      if (mediaMobileHomeAnimation.matches) {
        // Delete attr scroll titles and logo
        const scrollElements = document.querySelectorAll('[data-scroll-id]');
        scrollElements.forEach((scrollElement) => {
          const attr = scrollElement.getAttribute('data-scroll-id');
          const logo = scrollElement.querySelector('.home-sections__logo');
          if (logo) {
            logo.removeAttribute('data-scroll');
            logo.removeAttribute('data-scroll-target');
            logo.removeAttribute('data-scroll-sticky');
          }
          if (attr.indexOf('title') !== -1) {
            scrollElement.removeAttribute('data-scroll');
            scrollElement.removeAttribute('data-scroll-id');
          }
        });

        const onScroll = (args) => {
          for (const i in args.currentElements) {
            if (typeof args.currentElements[i] === 'object' && animationsMob[i]) {
              animationsMob[i](args.currentElements[i].el, args.currentElements[i].progress);
            }
          }
        };

        window.locoScroll = new LocomotiveScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          mobile: {
            smooth: true,
          },
        });
        window.locoScroll.on('scroll', onScroll);

        const animationsMob = {
          'scroll-sections': function (element, progress) {
            const logoSticky = element.querySelector('.home-sections__logo img');
            if (logoSticky) {
              logoSticky.style.transform = `translateY(${10 * progress * 25}vh)`;
            }
          },
        };
      }
    }
  });
} catch (e) { }

try {
  document.addEventListener('DOMContentLoaded', () => {
    const lazyVideos = [].slice.call(document.querySelector('.video-lazy'));

    if ('IntersectionObserver' in window) {
      var lazyVideoObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((video) => {
          if (video.isIntersecting) {
            for (const source in video.target.children) {
              const videoSource = video.target.children[source];
              if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove('lazy');
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach((lazyVideo) => {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  });
} catch (e) { }

try {
  const serviceHero = document.querySelector('.serviceHero--js');
  if (serviceHero) {
    const serviceHeroWidth = serviceHero.clientWidth / 2;
    document.querySelector('.serviceHero-right').style.width = `${serviceHeroWidth}px`;
  }

  window.addEventListener('resize', () => {
    const serviceHero = document.querySelector('.serviceHero--js');
    if (serviceHero) {
      const serviceHeroWidth = serviceHero.clientWidth / 2;
      document.querySelector('.serviceHero-right').style.width = `${serviceHeroWidth}px`;
    }
  });
} catch (e) { }

try {
  const faqItem = document.querySelectorAll('.serviceFaq-item__head');
  faqItem.forEach((faqElem) => {
    faqElem.addEventListener('click', () => {
      if (!faqElem.parentNode.classList.contains('active')) {
        faqElem.parentNode.classList.add('active');
      } else {
        faqElem.parentNode.classList.remove('active');
      }
    });
  });
} catch (e) { }

try {
  document.addEventListener('DOMContentLoaded', () => {
    new SV.Countdown(document.querySelector('#countdown'), {
      year: document.querySelector('#countdown').dataset.year,
      month: document.querySelector('#countdown').dataset.month,
      day: document.querySelector('#countdown').dataset.day,
      untilMessage: '',
      endMessage: 'Акция закончилась',
      showSeconds: false,
    });
  });
} catch (e) { }

try {
  const doctorSlider = new Swiper('.serviceDoctors-list .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 32,
    pagination: {
      el: '.serviceDoctors-list .js_slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.serviceDoctors-list .js_btn-slide-next',
      prevEl: '.serviceDoctors-list .js_btn-slide-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
} catch (e) { }

try {
  if (window.matchMedia('(max-width: 768px)').matches) {
    const doctorSlider = new Swiper('.serviceCart--js .swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      pagination: {
        el: '.serviceCart--js .js_slider-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.serviceCart--js .js_btn-slide-next',
        prevEl: '.serviceCart--js .js_btn-slide-prev',
      },
      slidesPerView: 1.2,
    });
  }
} catch (e) { }
